import React, {useEffect} from 'react';
import {DatePicker, Divider, Form, InputNumber, Modal, Select} from "antd";

interface NewGeoVictoriaToBukDateConfigurationModalProps {
    isNewConfigurationModalOpen: boolean,
    handleNewConfigurationModalOk: (executedAt: string, startDate: string, endDate: string) => void;
    handleNewConfigurationModalCancel: () => void;
    loading: boolean;
}

const { RangePicker } = DatePicker;

function NewGeoVictoriaToBukDateConfigurationModal({ isNewConfigurationModalOpen, handleNewConfigurationModalOk, handleNewConfigurationModalCancel, loading }: NewGeoVictoriaToBukDateConfigurationModalProps) {
    const [form] = Form.useForm();

    useEffect(() => {
        if(isNewConfigurationModalOpen) {
            form.setFieldValue('executedDate', null);
            form.setFieldValue('range', null);
        }
    }, [isNewConfigurationModalOpen])

    const onFinish = (values: any) => {
        console.log("Onfinish",values)
        if(values.executedDate && values.range) {
            handleNewConfigurationModalOk(values.executedDate.format('YYYY-MM-DD'), values.range[0].format('YYYY-MM-DD'), values.range[1].format('YYYY-MM-DD'));
        }
    };

    const validateRange = (rule: any, value: any) => {
        const executedDate = form.getFieldValue('executedDate');
        if (!value || !executedDate) {
            return Promise.resolve();
        }
        if (value[1].isAfter(executedDate, 'day')) {
            return Promise.reject('La fecha final del rango no debe ser posterior a la fecha de ejecución');
        }
        return Promise.resolve();
    };

    return (
        <Modal
            title="Nueva configuración"
            open={isNewConfigurationModalOpen}
            onOk={() => { form.submit(); }}
            onCancel={handleNewConfigurationModalCancel}
            okText="Nueva configuración"
            maskClosable={false}
            confirmLoading={loading}
        >
            <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item
                    name="executedDate"
                    label="Fecha de ejecución"
                    rules={[
                        { required: true, message: 'El campo es requerido' },
                    ]}
                >
                    <DatePicker placeholder="Selecciona la fecha" style={{ width: '100%' }} format="DD/MM/YYYY"/>
                </Form.Item>
                <Form.Item
                    name="range"
                    label="Rango de fechas"
                    rules={[
                        { required: true, message: 'El campo es requerido' },
                        { validator: validateRange },
                    ]}
                >
                    <RangePicker style={{ width: '100%' }}/>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default NewGeoVictoriaToBukDateConfigurationModal;
