import { Permission } from '../models/Permission';
import {User} from "../models/User";
import {CustomConfig} from "../models/CustomConfig";
import {DashboardConfig} from "../models/DashboardConfig";
import {
  DEFAULT_DASHBOARD_LOGO_HEIGHT,
  DEFAULT_DASHBOARD_LOGO_URL,
  DEFAULT_DASHBOARD_MENU_ITEM_ACTIVE_BG,
  DEFAULT_DASHBOARD_MENU_ITEM_SELECTED_BG,
  DEFAULT_DASHBOARD_MENU_ITEM_SELECTED_COLOR,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_SECONDARY_COLOR
} from "../config/Constants";

export const hasRoutePermissions = (
  permissions: string | string[],
  currentUser: any
) => {
  if (currentUser.id === 0) {
    return false;
  }

  const currentPermissions: Permission[] = currentUser.permissions || [];

  if (typeof permissions === 'string') {
    permissions = [permissions];
  }

  if (permissions.length === 0) {
    return true;
  } else {
    let check = 0;

    for (let permission of permissions) {
      permission = permission.trim().toUpperCase();

      if (
        currentPermissions.find((currentPermission: Permission) => {
          return currentPermission.code === permission;
        })
      ) {
        check++;
      }
    }

    return check === permissions.length;
  }
};

export const getFormattedUserData = (userData: any): User => {
  return {
    id: userData.id,
    username: userData.username,
    userType: userData.userType,
    name: userData.name,
    lastname: userData.lastname,
    email: userData.email,
    dialCode: userData.dialCode,
    phone: userData.phone || null,
    avatar: userData.avatar || null,
    createdAt: userData.createdAt,
    activatedAt: userData.activatedAt || null,
    disabledAt: userData.disabledAt || null,
    token: userData.sessionToken,
    permissions: userData.permissions
        ? userData.permissions.map((permission: any) => {
          return {
            code: permission.code,
            name: permission.name,
            description: permission.description,
            createdAt: permission.createdAt,
          };
        })
        : [],
    roles: userData.roles
        ? userData.roles.map((role: any) => {
          return {
            id: role.id,
            name: role.name,
            description: role.description,
            createdAt: role.createdAt,
          };
        })
        : [],
    allowedMenus: userData.menus,
    configSettings: userData.configSettings,
    company: {
      id: userData.company.id,
      name: userData.company.name,
      createdAt: userData.company.createdAt,
      updatedAt: userData.company.userType,
      countryCode: userData.company.countryCode,
      additionalConfig: {
        webConfiguration: {
          primaryColor: userData.company.additionalConfig.webConfiguration?.primaryColor || null,
          secondaryColor: userData.company.additionalConfig.webConfiguration?.secondaryColor || null,
          logo: {
            url: userData.company.additionalConfig.webConfiguration?.logo?.url || null,
            height: parseFloat(`${userData.company.additionalConfig.webConfiguration?.logo?.height}`) || null
          },
          menu: {
            itemSelectedColor: userData.company.additionalConfig.webConfiguration?.menu?.itemSelectedColor || null,
            itemSelectedBg: userData.company.additionalConfig.webConfiguration?.menu?.itemSelectedBg || null,
            itemActiveBg: userData.company.additionalConfig.webConfiguration?.menu?.itemActiveBg || null,
          }
        }
      }
    }
  };
}

export const getFormattedDashboardConfig = (user: User): DashboardConfig => {
  return {
    primaryColor: user.company.additionalConfig.webConfiguration.primaryColor || DEFAULT_PRIMARY_COLOR,
    secondaryColor: user.company.additionalConfig.webConfiguration.secondaryColor || DEFAULT_SECONDARY_COLOR,
    logoUrl: user.company.additionalConfig.webConfiguration.logo.url || DEFAULT_DASHBOARD_LOGO_URL,
    logoHeight: user.company.additionalConfig.webConfiguration.logo.height || DEFAULT_DASHBOARD_LOGO_HEIGHT,
    menuItemSelectedBg: user.company.additionalConfig.webConfiguration.menu.itemSelectedBg || DEFAULT_DASHBOARD_MENU_ITEM_SELECTED_BG,
    menuItemActiveBg: user.company.additionalConfig.webConfiguration.menu.itemActiveBg || DEFAULT_DASHBOARD_MENU_ITEM_ACTIVE_BG,
    menuItemSelectedColor: user.company.additionalConfig.webConfiguration.menu.itemSelectedColor || DEFAULT_DASHBOARD_MENU_ITEM_SELECTED_COLOR
  };
}
