import React, {useEffect, useState} from 'react';
import Layout from "../../../components/Layout/Layout";
import {
    Alert,
    Button,
    Card, Dropdown,
    Flex,
    Form,
    GetProp, MenuProps,
    message,
    Select,
    Table,
    TablePaginationConfig,
    TableProps
} from "antd";
import {ItemInterface} from "../../../models/ItemInterface";
import moment from "moment/moment";
import {DeleteOutlined, MoreOutlined, PlusOutlined} from "@ant-design/icons";
import Column from "antd/es/table/Column";
import {TitaskIntegrationService} from "../../../services/TitaskIntegrationService";
import {ApiErrorData} from "../../../models/ApiResponse";
import {ServerSideDataSchema} from "../../../models/ServerSideDataSchema";
import NewGeoVictoriaToBukDateConfigurationModal from "./components/NewGeoVictoriaToBukDateConfigurationModal";
import {User} from "../../../models/User";
import {useSelector} from "react-redux";
import dayjs from "dayjs";
import {FunctionsHelper} from "../../../utils/FunctionsHelper";

import './GeoVictoriaToBukDateConfiguration.scss';

export interface DateConfigurationRowSchema {
    id: number;
    executedAt: string;
    startDate: string;
    endDate: string;
    timezone: string;
    createdAt: Date;
}

interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
    filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

interface GeoVictoriaToBukDefaultConfigSchema {
    initDay: number;
    endDay: number;
    executeDay: number;
}

const startYear = 2023;
const currentYear = moment().year();
const PAGE_SIZE = 10;

function GeoVictoriaToBukDateConfiguration() {
    const { configSettings }: User = useSelector((state: any) => state.auth);
    const geoVictoriaToBukDefaultData: GeoVictoriaToBukDefaultConfigSchema = JSON.parse((configSettings.find((record) => { return record.key === 'GEOVICTORIA_BUK_DEFAULT_MIGRATION_TRIGGER' }) as any).value);
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState<DateConfigurationRowSchema[]>([]);
    const months: ItemInterface[] = [
        { value: 1, label: 'Enero' },
        { value: 2, label: 'Febrero' },
        { value: 3, label: 'Marzo' },
        { value: 4, label: 'Abril' },
        { value: 5, label: 'Mayo' },
        { value: 6, label: 'Junio' },
        { value: 7, label: 'Julio' },
        { value: 8, label: 'Agosto' },
        { value: 9, label: 'Septiembre' },
        { value: 10, label: 'Octubre' },
        { value: 11, label: 'Noviembre' },
        { value: 12, label: 'Diciembre' }
    ];
    const years: ItemInterface[] = [];
    const [form] = Form.useForm();
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: PAGE_SIZE,
        },
    });
    const [selectedMonthFilter, setSelectedMonthFilter] = useState<number | undefined>();
    const [selectedYearFilter, setSelectedYearFilter] = useState<number | undefined>();

    for (let year = startYear; year <= currentYear; year++) {
        years.push({ value: year, label: year.toString() });
    }

    /* New Configuration */
    const [newConfigurationLoading, setNewConfigurationLoading] = useState(false);
    const [isNewConfigurationModalOpen, setIsNewConfigurationModalOpen] = useState(false);

    const getHelperText = (): React.ReactNode | string => {
        const currentMonth = dayjs().month() + 1;
        const currentYear = dayjs().year();
        const startDateRange = `${geoVictoriaToBukDefaultData.initDay}/${currentMonth <= 9 ? `0${currentMonth}`: currentMonth}/${currentYear}`;
        const endDateRange = `${geoVictoriaToBukDefaultData.endDay}/${currentMonth <= 9 ? `0${currentMonth}`: currentMonth}/${currentYear}`;
        return <>La siguiente programación por defecto está configurada para el <b>{geoVictoriaToBukDefaultData.executeDay} de {(FunctionsHelper.getMonthName(currentMonth)).toLowerCase()} del {currentYear}</b> y abarcará el rango de fecha siguiente:  <b>{startDateRange} - {endDateRange}</b></>
    }

    const onFinish = (values: any) => {
        setSelectedMonthFilter(values.month);
        setSelectedYearFilter(values.year);
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        })
    };

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        fetchData();
    }, [tableParams.pagination?.current, tableParams.pagination?.pageSize, selectedMonthFilter, selectedYearFilter]);

    const init = async () => {
        setLoading(true);

        await fetchData();

        setLoading(false);
    }

    const fetchData = async (currentPage?: number) => {
        setLoading(true);


        const getCustomGeoVictoriaMigrationTriggerResponse = await TitaskIntegrationService.getCustomGeoVictoriaMigrationTrigger({
            page: currentPage || (tableParams.pagination?.current || 1),
            pageSize: tableParams.pagination?.pageSize || PAGE_SIZE,
            month: selectedMonthFilter,
            year: selectedYearFilter
        });

        if(getCustomGeoVictoriaMigrationTriggerResponse.success) {
            const data = getCustomGeoVictoriaMigrationTriggerResponse.data as ServerSideDataSchema<DateConfigurationRowSchema>;
            setDataSource(data.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: parseInt(`${data.totalRowsFiltered}`)
                }
            })
        }else {
            const error = getCustomGeoVictoriaMigrationTriggerResponse.data as ApiErrorData;
            messageApi.error(error.message as string || 'Hubo un error al intentar obtener los datos de la grilla, por favor inténtalo nuevamente.', 3.5);
        }

        setLoading(false);
    }

    const handleTableChange: TableProps['onChange'] = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setDataSource([]);
        }
    };

    const getMonthName = (month: number): string => {
        const selectedMonth = months.find((row) => {
            return row.value === month;
        });

        return selectedMonth?.label || '----';
    }

    const deleteConfiguration = async (row: DateConfigurationRowSchema) => {
        setLoading(true);
        const deleteResponse = await TitaskIntegrationService.deleteCustomGeoVictoriaMigrationTrigger(row.id);

        if(deleteResponse.success) {
            await fetchData(1);
            messageApi.success(`Se eliminó la configuración seleccionada de manera satisfactoria.`, 3.5);
        }else {
            const error = deleteResponse.data as ApiErrorData;
            messageApi.error(error.message as string || 'Hubo un error al intentar eliminar la configuración seleccionada, por favor inténtalo nuevamente.', 3.5);
            setLoading(false);
        }
    }

    const getDropdownMenu = (row: DateConfigurationRowSchema): MenuProps['items'] => {
        return [
            {
                key: '1',
                label: <span onClick={() => { deleteConfiguration(row); }}><DeleteOutlined/> Eliminar configuración</span>,
            },
        ];
    }

    const showNewConfigurationModal = () => {
        if(!loading) {
            setIsNewConfigurationModalOpen(true);
        }
    }

    const handleNewConfigurationModalOk = async (executedAt: string, startDate: string, endDate: string) => {
        if(!newConfigurationLoading) {
            setNewConfigurationLoading(true);
            const createResponse = await TitaskIntegrationService.createCustomGeoVictoriaMigrationTrigger({
                executedAt,
                startDate,
                endDate
            });

            if(createResponse.success) {
                setIsNewConfigurationModalOpen(false);
                messageApi.success('Se registró satisfactoriamente la nueva configuración.', 3.5);
            }else {
                const error = createResponse.data as ApiErrorData;
                messageApi.error(error.message as string || 'Hubo un error al intentar crear la nueva configuración, por favor inténtalo nuevamente.', 3.5);
                setLoading(false);
            }

            setNewConfigurationLoading(false);
            await fetchData(1);
        }
    }

    const handleNewConfigurationModalCancel = () => {
        if(!newConfigurationLoading) {
            setIsNewConfigurationModalOpen(false);
        }
    }

    return (
        <>
            { contextHolder }

            <Layout breadcrumb={[
                { title: 'Geovictoria' },
                { title: 'Configuración de fechas' },
            ]}>
                <Alert message={getHelperText()} type="info" showIcon />

                <Card style={{ marginTop: '12px' }}>
                    <Flex justify="space-between">
                        <Form form={form} layout="inline" onFinish={onFinish}>
                            <Form.Item
                                name="month"
                                label="Mes"
                            >
                                <Select style={{ width: '125px' }} placeholder="-Seleccione-" allowClear disabled={loading}>
                                    {
                                        months.map((month) => (<Select.Option key={month.value} value={month.value}>{month.label}</Select.Option>))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="year"
                                label="Año"
                            >
                                <Select style={{ width: '125px' }} placeholder="-Seleccione-" allowClear disabled={loading}>
                                    {
                                        years.map((year) => (<Select.Option key={year.value} value={year.value}>{year.label}</Select.Option>))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item shouldUpdate>
                                {() => (
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        loading={loading}
                                    >
                                        Filtrar
                                    </Button>
                                )}
                            </Form.Item>
                        </Form>

                        <Button style={{ float: 'right' }} type="primary" loading={loading} onClick={() => { showNewConfigurationModal(); }}><PlusOutlined /> Nueva configuración</Button>
                    </Flex>
                </Card>
                <Card style={{ marginTop: '12px' }}>
                    <Table<DateConfigurationRowSchema> dataSource={dataSource} bordered loading={loading} size="small" scroll={{ x: 1300 }} pagination={tableParams.pagination} rowKey={(record) => record.id} onChange={handleTableChange}>
                        <Column title="Fecha de ejecución" dataIndex="executedAt" key="executedAtFormatted" render={(executedAt: string) => (
                            <span>{dayjs(executedAt, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>
                        )}/>
                        <Column title="Rango de fechas por migrar" key="range" render={(record: DateConfigurationRowSchema) => (
                            <span>{`${dayjs(record.startDate, 'YYYY-MM-DD').format('DD/MM/YYYY')} - ${dayjs(record.endDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}`}</span>
                        )}/>
                        <Column title="Zona horaria" dataIndex="timezone" key="timezone" />
                        <Column
                            title="Fecha de registro"
                            dataIndex="createdAt"
                            key="createdAtFormatted"
                            render={(createdAt: Date) => (
                                <span>{moment(createdAt).format('DD/MM/YYYY h:mm A')}</span>
                            )}
                        />
                        <Column width={120} align="center" title="" key="actions" render={(row) => (
                            <Dropdown menu={ { items: getDropdownMenu(row) } } placement="bottomLeft" trigger={['click']}>
                                <Button size="small"><MoreOutlined /></Button>
                            </Dropdown>
                        )} />
                    </Table>
                </Card>

                {/* Modals */}
                <NewGeoVictoriaToBukDateConfigurationModal isNewConfigurationModalOpen={isNewConfigurationModalOpen} handleNewConfigurationModalOk={handleNewConfigurationModalOk} handleNewConfigurationModalCancel={handleNewConfigurationModalCancel} loading={newConfigurationLoading}/>
            </Layout>
        </>
    );
}

export default GeoVictoriaToBukDateConfiguration;
