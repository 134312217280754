export const DEFAULT_PRIMARY_COLOR = '#41b579';
export const DEFAULT_SECONDARY_COLOR = '#cfe8fc';
export const DEFAULT_DASHBOARD_LOGO_URL = `/logo_touchlatam_white.png`;
export const DEFAULT_DASHBOARD_LOGO_HEIGHT = 40;
export const DEFAULT_DASHBOARD_MENU_ITEM_SELECTED_BG = '#E6F4FF';
export const DEFAULT_DASHBOARD_MENU_ITEM_ACTIVE_BG = '#CCE9FF';
export const DEFAULT_DASHBOARD_MENU_ITEM_SELECTED_COLOR = '#1677FF';
export const USER_TYPES = {
    SUPER_ADMIN: 'SUPER_ADMIN',
    REGULAR_ACCOUNT: 'REGULAR_ACCOUNT'
}
