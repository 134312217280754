import { SAVE, UPDATE } from './ActionTypes';
import {REMOVE} from "../menuActions/ActionTypes";

export function save(payload: any) {
    return {
        type: SAVE,
        payload,
    };
}

export function update(payload?: any) {
    return {
        type: UPDATE,
        payload,
    };
}

export function remove() {
    return {
        type: REMOVE
    };
}

const DashboardConfigAction = {
    save,
    update,
    remove
};

export default DashboardConfigAction;
