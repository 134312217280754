import { SAVE, UPDATE, REMOVE } from './ActionTypes';

export function save(payload: any) {
  return {
    type: SAVE,
    payload,
  };
}

export function update(payload?: any) {
  return {
    type: UPDATE,
    payload,
  };
}

export function remove() {
  return {
    type: REMOVE
  };
}

const MenuAction = {
  save,
  update,
  remove,
};

export default MenuAction;
