import React, {useEffect, useState} from 'react';
import {
    GeoVictoriaMigrationLogRowSchema
} from "../../../GeoVictoria/GeoVictoriaToBukMigration/GeoVictoriaToBukMigration";
import {Card, Divider, Form, Input, Modal} from "antd";
import JsonView from "@uiw/react-json-view";
import {githubLightTheme} from "@uiw/react-json-view/githubLight";

interface BukMigrationDetailModalProps {
    selectedLog: GeoVictoriaMigrationLogRowSchema;
    handleBukMigrationDetailModalCancel: () => void;
    isBukMigrationDetailModalOpen: boolean;
}

const COLLAPSE_NUMBER = 2;

function BukMigrationDetailModal({ selectedLog, handleBukMigrationDetailModalCancel, isBukMigrationDetailModalOpen }: BukMigrationDetailModalProps) {
    const [form] = Form.useForm();
    const [collapse, setCollapse] = useState(COLLAPSE_NUMBER);

    useEffect(() => {
        if(isBukMigrationDetailModalOpen) {
            form.resetFields();
        }
    }, [isBukMigrationDetailModalOpen]);

    return (
        <Modal
            title={`Detalles de migración [ID: ${selectedLog.id}]`}
            open={isBukMigrationDetailModalOpen}
            footer={false}
            onCancel={handleBukMigrationDetailModalCancel}
            maskClosable={false}
            destroyOnClose
        >
            <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

            <Form layout="vertical">
                <Form.Item
                    label="Tipo de proceso"
                >
                    <Input readOnly value={ selectedLog.key === 'BUK_LICENCE_MIGRATION_TO_GEO_VICTORIA_PROCESS' ?  'MIGRACIÓN DE LICENCIA' : selectedLog.key }/>
                </Form.Item>


                <Form.Item
                    name="params"
                    label="Parámetros de entrada"
                >
                    <Card>
                        <JsonView value={selectedLog.params} style={githubLightTheme} collapsed={collapse} />
                    </Card>
                </Form.Item>


                <Form.Item
                    name="payload"
                    label="Respuesta"
                >
                    <Card>
                        <JsonView value={selectedLog.payload} style={githubLightTheme} collapsed={collapse} />
                    </Card>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default BukMigrationDetailModal;
