import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {User} from "../../models/User";
import {MenuType} from "../../config/Config";
import {FunctionsHelper} from "../../utils/FunctionsHelper";

type PrivateRouteProps = {
  outlet: JSX.Element;
};

function GuestRoute({ outlet }: PrivateRouteProps) {
  const user: User = useSelector((state: any) => state.auth);
  let defaultRoute = "/home";

  if (user?.id === 0) {
    return outlet;
  }

  const menus = FunctionsHelper.flattenMenuArray(user.allowedMenus).filter((menu) => {
    return [
      MenuType.STANDARD_MENU as string,
      MenuType.IFRAME_VIEWER as string,
    ].includes(menu.menuTypeId);
  });

  if (menus.length > 0) {
    const firstMenu = menus[0];
    if (firstMenu) {
      if (firstMenu.menuTypeId === MenuType.STANDARD_MENU && firstMenu.redirectLink) {
        defaultRoute = firstMenu.redirectLink;
      }

      if (firstMenu.menuTypeId === MenuType.IFRAME_VIEWER && firstMenu.redirectLink) {
        defaultRoute = `/dynamic-viewer/${firstMenu.code}`;
      }

      if (firstMenu.menuTypeId === MenuType.PARENT_MENU) {
        const childrenMenus = firstMenu.menus.filter((menu: any) => {
          return [
            MenuType.STANDARD_MENU as string,
            MenuType.IFRAME_VIEWER as string
          ].includes(menu.menuTypeId);
        });
        if(childrenMenus.length > 0) {
          const firstChildrenMenu = childrenMenus[0];

          if(firstChildrenMenu.menuTypeId === MenuType.STANDARD_MENU && firstChildrenMenu.redirectLink) {
            defaultRoute = firstChildrenMenu.redirectLink;
          }

          if(firstChildrenMenu.menuTypeId === MenuType.IFRAME_VIEWER && firstChildrenMenu.redirectLink) {
            defaultRoute = `/dynamic-viewer/${firstChildrenMenu.code}`;
          }
        }
      }
    }
  }

  return <Navigate to={defaultRoute} />;
}

export default GuestRoute;
