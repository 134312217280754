import React from "react";
import {User} from "../models/User";
import DynamicIcon from "../components/DynamicIcon/DynamicIcon";
import {MenuType} from "./Config";
import {Menu} from "../models/Menu";
import {FunctionsHelper} from "../utils/FunctionsHelper";

const HOME_PATH = '/home';


const requiredHome = (menus: Menu[]) => {
    const availableMenus = FunctionsHelper.flattenMenuArray(menus).filter((menu) => {
        return [
            MenuType.STANDARD_MENU as string,
            MenuType.IFRAME_VIEWER as string,
        ].includes(menu.menuTypeId);
    });

    return availableMenus.length === 0;
};

const dashboardMenu = (user: User) => {
    const menus = user.allowedMenus || [];

    const routesFormatted = menus.map((parentMenu) => {
        let parentPath: string | null = HOME_PATH;

        switch (parentMenu.menuTypeId) {
            case MenuType.IFRAME_VIEWER:
                parentPath = `/dynamic-viewer/${parentMenu.code}`;
                break;
            case MenuType.URL_REDIRECT:
                parentPath = `/download/${parentMenu.code}`;
                break;
            case MenuType.PARENT_MENU:
                parentPath = null;
                break;
            case MenuType.STANDARD_MENU:
                parentPath = parentMenu.redirectLink || HOME_PATH;
                break;
        }

        let menuRow: any = {
            key: parentMenu.code,
            path: parentPath,
            label: parentMenu.title,
            icon: parentMenu.icon ? <DynamicIcon type={parentMenu.icon.trim()}/> : <DynamicIcon type={'SwitcherOutlined'}/>,
            breadcrumb: [parentMenu.title],
            keys: [parentMenu.code],
        }

        if(parentMenu.menus && parentMenu.menus.length > 0) {
            menuRow.children = parentMenu.menus.map((childMenu): any => {
                let childPath = HOME_PATH;

                switch (childMenu.menuTypeId) {
                    case 'IFRAME_VIEWER':
                        childPath = `/dynamic-viewer/${childMenu.code}`;
                        break;
                    case 'URL_REDIRECT':
                        childPath = `/download/${childMenu.code}`;
                        break;
                    case 'STANDARD_MENU':
                        childPath = childMenu.redirectLink || HOME_PATH;
                        break;
                }

                return {
                    key: childMenu.code,
                    path: childPath,
                    label: childMenu.title,
                    icon: childMenu.icon ? <DynamicIcon type={childMenu.icon}/> : <></>,
                    breadcrumb: [parentMenu.title, childMenu.title],
                    keys: [parentMenu.code, childMenu.code]
                };
            });
        }

        return menuRow;
    });

    if(requiredHome(menus)) {
        routesFormatted.unshift({
            key: 'HOME__PATH__UNSHIFT',
            path: HOME_PATH,
            label: 'Home',
            icon: <DynamicIcon type="HomeOutlined"/>,
            breadcrumb: ['Home'],
            keys: ['HOME__PATH__UNSHIFT']
        });
    }

    return {
        routes: routesFormatted
    };
};

export default dashboardMenu;
