import MainBackendAxios from '../utils/MainBackendAxios';
import {ApiErrorData, ApiResponse} from "../models/ApiResponse";
import {FunctionsHelper} from "../utils/FunctionsHelper";
import {User} from "../models/User";
import {getFormattedUserData} from "../utils/AuthUtils";

export const UserService = {
  sendPasswordRecovery: async (username: string): Promise<ApiResponse<{ userId: number, expiresInMs: number } | ApiErrorData>> => {
    try {
      const response = await MainBackendAxios.post(`/user/send-recovery-token-by-username`, { username: username });

      return {
        success: true,
        status: response.status,
        data: response.data.data,
      };
    } catch (err: any) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  sendNewActivationCode: async (userId: number): Promise<ApiResponse<{ executedAction: boolean, userId: number, expiredCodeAt: Date } | ApiErrorData>> => {
    try {
      const response = await MainBackendAxios.post(`/user/send-new-activation-code/${userId}`);

      return {
        success: true,
        status: response.status,
        data: response.data.data,
      };
    } catch (err: any) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  verifyRecoveryToken: async (token: string): Promise<ApiResponse<{ userId: number, email: string } | ApiErrorData>> => {
    try {
      const response = await MainBackendAxios.post(`/user/verify-recovery-token`, { recoveryToken: token });

      return {
        success: true,
        status: response.status,
        data: response.data.data,
      };
    } catch (err: any) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  updatePasswordByRecoveryToken: async (token: string, newPassword: string): Promise<ApiResponse<{ executedAction: boolean, user: User } | ApiErrorData>> => {
    try {
      const response = await MainBackendAxios.post(`/user/update-password-by-recovery-token`, { recoveryToken: token, newPassword });
      const userData = response?.data?.data.user;

      return {
        success: true,
        status: response.status,
        data: {
          executedAction: response.data.executedAction,
          user: getFormattedUserData(userData)
        },
      };
    } catch (err: any) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  verifyUser: async (userId: number, activationCode: string): Promise<ApiResponse<{ executedAction: boolean, user: User } | ApiErrorData>> => {
    try {
      const response = await MainBackendAxios.post(`/user/verify-by-activation-code/${userId}`, { activationCode });
      const userData = response?.data?.data.user;

      return {
        success: true,
        status: response.status,
        data: {
          executedAction: response.data.executedAction,
          user: getFormattedUserData(userData)
        },
      };
    } catch (err: any) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
};
