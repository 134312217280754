import {theme, ThemeConfig} from "antd";
import {useSelector} from "react-redux";
import {CustomConfig} from "../models/CustomConfig";
import {DashboardConfig} from "../models/DashboardConfig";

export const useThemeLayoutConfig = (): { theme: ThemeConfig, customValues: CustomConfig } => {
    const themeToken = theme.useToken();
    const dashboardConfig: DashboardConfig = useSelector((state: any) => state.dashboardConfig);


    const customThemeConfig: ThemeConfig =  {
        token: {
            colorPrimary: dashboardConfig.primaryColor,
        },
        components: {
            Menu: {
                itemSelectedBg: dashboardConfig.menuItemSelectedBg,
                itemActiveBg: dashboardConfig.menuItemActiveBg,
                itemSelectedColor: dashboardConfig.menuItemSelectedColor,
            }
        },
    };

    return {
        theme: {
            ...themeToken,
            ...customThemeConfig,
        },
        customValues: {
            secondaryColor: dashboardConfig.secondaryColor
        }
    };
};
