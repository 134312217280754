import {ApiErrorData, ApiResponse} from "../models/ApiResponse";
import {User} from "../models/User";
import MainBackendAxios from "../utils/MainBackendAxios";
import {getFormattedUserData} from "../utils/AuthUtils";
import {FunctionsHelper} from "../utils/FunctionsHelper";

export const CompanyService = {
    updateCustomConfigByCurrentUser: async (params: UpdateCustomConfigByCurrentUserRequestDto, file?: File): Promise<ApiResponse<{ executedAction: boolean } | ApiErrorData>>  => {
        try {
            const formData = new FormData();
            formData.append('primaryColor', params.primaryColor);
            formData.append('secondaryColor', params.secondaryColor);
            formData.append('itemSelectedBg', params.itemSelectedBg);
            formData.append('itemSelectedColor', params.itemSelectedColor);
            formData.append('itemActiveBg', params.itemActiveBg);

            if(params.logoHeight) {
                formData.append('logoHeight', `${params.logoHeight}`);
            }

            if(file) {
                formData.append('file', file);
            }

            const response = await MainBackendAxios.put(`/company/update-custom-config-by-current-user`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
};

export interface UpdateCustomConfigByCurrentUserRequestDto {
    primaryColor: string;
    secondaryColor: string;
    itemSelectedBg: string;
    itemActiveBg: string;
    itemSelectedColor: string;
    logoHeight?: number;
}
