import React, {useEffect, useState} from 'react';
import Layout from "../../../components/Layout/Layout";
import {
    Button,
    Card, DatePicker,
    Dropdown,
    Flex,
    Form,
    GetProp,
    MenuProps,
    message,
    Select,
    Table,
    TablePaginationConfig, TableProps
} from "antd";

import './BukToGeoVictoriaMigration.scss';
import dayjs from "dayjs";
import {MoreOutlined, PlusOutlined, SearchOutlined} from "@ant-design/icons";
import Column from "antd/es/table/Column";
import moment from "moment/moment";
import BukMigrationModal from "./Components/BukMigrationModal";
import BukMigrationDetailModal from "./Components/BukMigrationDetailModal";
import {TitaskIntegrationService} from "../../../services/TitaskIntegrationService";
import {ServerSideDataSchema} from "../../../models/ServerSideDataSchema";
import {ApiErrorData} from "../../../models/ApiResponse";
import {GeoVictoriaMigrationLogRowSchema} from "../../GeoVictoria/GeoVictoriaToBukMigration/GeoVictoriaToBukMigration";

export interface BukMigrationLogRowSchema {
    id: number;
    key: string;
    type: string;
    status: string;
    createdAt: Date;
    finishedAt: Date | null;
    params: any;
    payload: any;
}

interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
    filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

const PAGE_SIZE = 10;
const { RangePicker } = DatePicker;

function BukToGeoVictoriaMigration() {
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [dataSource, setDataSource] = useState<BukMigrationLogRowSchema[]>([]);
    const [startDateFilter, setStartDateFilter] = useState(moment().add(-30, 'days').format('YYYY-MM-DD'));
    const [endDateFilter, setEndDateFilter] = useState(moment().format('YYYY-MM-DD'));
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: PAGE_SIZE,
        },
    });
    const [bukMigrationLoading, setBukMigrationLoading] = useState(false);
    const [isBukMigrationModalOpen, setIsBukMigrationModalOpen] = useState(false);
    const [isBukMigrationDetailModalOpen, setIsBukMigrationDetailModalOpen] = useState(false);
    const [selectedLog, setSelectedLog] = useState<BukMigrationLogRowSchema | undefined>();

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        fetchData();
    }, [tableParams.pagination?.current, tableParams.pagination?.pageSize, startDateFilter, endDateFilter]);

    const init = async () => {
        setLoading(true);

        await fetchData();

        setLoading(false);
    }

    const fetchData = async (currentPage?: number) => {
        setLoading(true);


        const getBukMigrationTriggerLogResponse = await TitaskIntegrationService.getBukMigrationTriggerLog({
            page: currentPage || (tableParams.pagination?.current || 1),
            pageSize: tableParams.pagination?.pageSize || PAGE_SIZE,
            startDate: startDateFilter,
            endDate: endDateFilter
        });

        if(getBukMigrationTriggerLogResponse.success) {
            const data = getBukMigrationTriggerLogResponse.data as ServerSideDataSchema<GeoVictoriaMigrationLogRowSchema>;
            setDataSource(data.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: parseInt(`${data.totalRowsFiltered}`)
                }
            })
        }else {
            const error = getBukMigrationTriggerLogResponse.data as ApiErrorData;
            messageApi.error(error.message as string || 'Hubo un error al intentar obtener los datos de la grilla, por favor inténtalo nuevamente.', 3.5);
        }

        setLoading(false);
    }

    const getDropdownMenu = (row: BukMigrationLogRowSchema): MenuProps['items'] => {
        return [
            {
                key: '1',
                label: <span onClick={() => { showMigrationDetail(row) }}><SearchOutlined /> Ver detalles</span>,
            },
        ];
    }

    const showMigrationDetail = (row: BukMigrationLogRowSchema) => {
        setSelectedLog(row);
        setIsBukMigrationDetailModalOpen(true);
    }

    const handleTableChange: TableProps['onChange'] = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setDataSource([]);
        }
    };

    const onFinish = (values: any) => {
        setStartDateFilter(values.range[0].format('YYYY-MM-DD'));
        setEndDateFilter(values.range[1].format('YYYY-MM-DD'));
    };

    const showBukMigrationModal = () => {
        if(!loading) {
            setIsBukMigrationModalOpen(true);
        }
    }

    const handleBukMigrationModalOk = async (date: string, type: string) => {
        if(!bukMigrationLoading) {
            setBukMigrationLoading(true);
            const executeMigrationResponse = await TitaskIntegrationService.executeLicenseBukToGeoVictoriaManualMigration({
                date,
                type
            });

            if(executeMigrationResponse.success) {
                setBukMigrationLoading(false);
                messageApi.success('Se realizó la migración satisfactoriamente.', 3.5);
                setBukMigrationLoading(false);
                setIsBukMigrationModalOpen(false);
            }else {
                const error = executeMigrationResponse.data as ApiErrorData;
                messageApi.error(error.message as string || 'Hubo un error al intentar realizar la migración, por favor inténtalo nuevamente.', 3.5);
                setLoading(false);
            }

            setBukMigrationLoading(false);
            await fetchData(1);
        }
    }

    const handleBukMigrationModalCancel = () => {
        if(!bukMigrationLoading) {
            setIsBukMigrationModalOpen(false);
        }
    }

    const handleBukMigrationDetailModalCancel = () => {
        setIsBukMigrationDetailModalOpen(false);
    }

    return (
        <>
            { contextHolder }

            <Layout breadcrumb={[
                { title: 'Buk' },
                { title: 'Migración a Geovictoria' },
            ]}>
                <Card>
                    <Flex justify="space-between">
                        <Form form={form} layout="inline" onFinish={onFinish}>
                            <Form.Item
                                name="range"
                                label="Filtro por fechas"
                            >
                                <RangePicker allowClear={false}  defaultValue={[ dayjs().add(-30, 'day'), dayjs() ]}/>
                            </Form.Item>
                            <Form.Item shouldUpdate>
                                {() => (
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        loading={loading}
                                    >
                                        Filtrar
                                    </Button>
                                )}
                            </Form.Item>
                        </Form>

                        <Button style={{ float: 'right' }} type="primary" loading={loading} onClick={() => { showBukMigrationModal(); }}><PlusOutlined /> Migración Manual</Button>
                    </Flex>
                </Card>

                <Card style={{ marginTop: '12px' }}>
                    <Table<BukMigrationLogRowSchema> dataSource={dataSource} bordered loading={loading} size="small" scroll={{ x: 1300 }} pagination={tableParams.pagination} rowKey={(record) => record.id} onChange={handleTableChange}>
                        <Column title="ID" dataIndex="id" key="id" />
                        <Column title="Tipo de proceso" dataIndex="key" key="key" render={(key: string) => (
                            <span>{key === 'BUK_LICENCE_MIGRATION_TO_GEO_VICTORIA_PROCESS' ?  'MIGRACIÓN DE LICENCIA' : key}</span>
                        )}/>
                        <Column title="Modalidad" dataIndex="type" key="type" render={(type: string) => (
                            <span>{type === 'MANUAL' ? 'Manual' : 'Automático'}</span>
                        )}/>
                        <Column title="Status" dataIndex="status" key="status" render={(status: string) => (
                            <span>{status === 'OK' ? 'Finalizado' : (status === 'PENDING' ? 'Pendiente' : 'Finalizado Parcialmente')}</span>
                        )}/>
                        <Column
                            width={160}
                            align="center"
                            title="Fecha de ocurrencia"
                            dataIndex="createdAt"
                            key="createdAtFormatted"
                            render={(createdAt: Date) => (
                                <span>{moment(createdAt).format('DD/MM/YYYY h:mm A')}</span>
                            )}
                        />
                        <Column
                            width={160}
                            align="center"
                            title="Fecha de finalización"
                            dataIndex="finishedAt"
                            key="finishedAtFormatted"
                            render={(finishedAt: Date) => (
                                <span>{finishedAt ? moment(finishedAt).format('DD/MM/YYYY h:mm A') : 'Sin finalizar'}</span>
                            )}
                        />
                        <Column width={120} align="center" title="" key="actions" render={(row) => (
                            <Dropdown menu={ { items: getDropdownMenu(row) } } placement="bottomLeft" trigger={['click']}>
                                <Button size="small"><MoreOutlined /></Button>
                            </Dropdown>
                        )} />
                    </Table>
                </Card>

                {/* Modals */}
                <BukMigrationModal isBukMigrationModalOpen={isBukMigrationModalOpen} handleBukMigrationModalOk={handleBukMigrationModalOk} handleBukMigrationModalCancel={handleBukMigrationModalCancel} loading={bukMigrationLoading}/>

                {
                    selectedLog && (
                        <BukMigrationDetailModal selectedLog={selectedLog} isBukMigrationDetailModalOpen={isBukMigrationDetailModalOpen} handleBukMigrationDetailModalCancel={handleBukMigrationDetailModalCancel} />
                    )
                }
            </Layout>
        </>
    );
}

export default BukToGeoVictoriaMigration;
