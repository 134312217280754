import {SAVE, UPDATE} from "../actions/dashboardConfigActions/ActionTypes";
import {Action} from "../models/Action";
import {DashboardConfig} from "../../models/DashboardConfig";
import {
    DEFAULT_DASHBOARD_LOGO_HEIGHT,
    DEFAULT_DASHBOARD_LOGO_URL,
    DEFAULT_DASHBOARD_MENU_ITEM_ACTIVE_BG,
    DEFAULT_DASHBOARD_MENU_ITEM_SELECTED_BG,
    DEFAULT_DASHBOARD_MENU_ITEM_SELECTED_COLOR,
    DEFAULT_PRIMARY_COLOR,
    DEFAULT_SECONDARY_COLOR
} from "../../config/Constants";
import {REMOVE} from "../actions/menuActions/ActionTypes";

const defaultStore: DashboardConfig = {
    primaryColor: DEFAULT_PRIMARY_COLOR,
    secondaryColor: DEFAULT_SECONDARY_COLOR,
    logoUrl: DEFAULT_DASHBOARD_LOGO_URL,
    logoHeight: DEFAULT_DASHBOARD_LOGO_HEIGHT,
    menuItemSelectedBg: DEFAULT_DASHBOARD_MENU_ITEM_SELECTED_BG,
    menuItemActiveBg: DEFAULT_DASHBOARD_MENU_ITEM_ACTIVE_BG,
    menuItemSelectedColor: DEFAULT_DASHBOARD_MENU_ITEM_SELECTED_COLOR
};

export default function DashboardConfigReducer(
    state: DashboardConfig = defaultStore,
    action: Action
) {
    switch (action.type) {
        case SAVE:
            return {
                primaryColor: action.payload.primaryColor,
                secondaryColor: action.payload.secondaryColor,
                logoUrl: action.payload.logoUrl,
                logoHeight: action.payload.logoHeight,
                menuItemSelectedBg: action.payload.menuItemSelectedBg,
                menuItemActiveBg: action.payload.menuItemActiveBg,
                menuItemSelectedColor: action.payload.menuItemSelectedColor,
            };
        case UPDATE:
            return {
                ...state,
                ...action.payload,
            };
        case REMOVE:
            return {
                ...defaultStore
            };
        default:
            return state;
    }
}
