import {ApiErrorData, ApiResponse} from "../models/ApiResponse";
import MainBackendAxios from "../utils/MainBackendAxios";
import {FunctionsHelper} from "../utils/FunctionsHelper";
import {ServerSideDataSchema} from "../models/ServerSideDataSchema";
import {
    DateConfigurationRowSchema
} from "../scenes/GeoVictoria/GeoVictoriaToBukDateConfiguration/GeoVictoriaToBukDateConfiguration";
import {
    GeoVictoriaMigrationLogRowSchema
} from "../scenes/GeoVictoria/GeoVictoriaToBukMigration/GeoVictoriaToBukMigration";

export const TitaskIntegrationService = {
    getCustomGeoVictoriaMigrationTrigger: async (params: {page: number, pageSize: number, month?: number, year?: number}): Promise<ApiResponse<ServerSideDataSchema<DateConfigurationRowSchema> | ApiErrorData>> => {
        try {
            const response = await MainBackendAxios.post(`/custom-geo-victoria-migration-trigger/get-by-filters-and-paginate`, params);

            return {
                success: true,
                status: response.status,
                data: response.data.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    deleteCustomGeoVictoriaMigrationTrigger: async (id: number): Promise<ApiResponse<{ executedAction: boolean } | ApiErrorData>> => {
        try {
            const response = await MainBackendAxios.delete(`/custom-geo-victoria-migration-trigger/delete/${id}`);

            return {
                success: true,
                status: response.status,
                data: response.data.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    createCustomGeoVictoriaMigrationTrigger: async (params: { executedAt: string, startDate: string, endDate: string }): Promise<ApiResponse<{ createdId: number } | ApiErrorData>> => {
        try {
            const response = await MainBackendAxios.post(`/custom-geo-victoria-migration-trigger/create`, params);

            return {
                success: true,
                status: response.status,
                data: response.data.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    getGeoVictoriaMigrationTriggerLog: async (params: {page: number, pageSize: number, startDate: string, endDate: string, type?: 'MANUAL' | 'AUTOMATIC'}): Promise<ApiResponse<ServerSideDataSchema<GeoVictoriaMigrationLogRowSchema> | ApiErrorData>> => {
        try {
            const response = await MainBackendAxios.post(`/geo-victoria-migration-trigger-log/get-by-filters-and-paginate`, params);

            return {
                success: true,
                status: response.status,
                data: response.data.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    executeGeoVictoriaToBukManualMigration: async (params: {startDate: string, endDate: string, frequency: 'diaria' | 'mensual'}): Promise<ApiResponse<{ executedAction: boolean } | ApiErrorData>> => {
        try {
            const response = await MainBackendAxios.post(`/geo-victoria-migration-trigger-log/execute-manual-migration-geo-victoria-to-buk`, params);

            return {
                success: true,
                status: response.status,
                data: response.data.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    findGeoVictoriaMigrationTriggerLogById: async (id: number): Promise<ApiResponse<GeoVictoriaMigrationLogRowSchema | ApiErrorData>> => {
        try {
            const response = await MainBackendAxios.get(`/geo-victoria-migration-trigger-log/find-by-id/${id}`,);

            return {
                success: true,
                status: response.status,
                data: response.data.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    executeLicenseBukToGeoVictoriaManualMigration: async (params: {date: string, type: string}): Promise<ApiResponse<{ executedAction: boolean } | ApiErrorData>> => {
        try {
            const response = await MainBackendAxios.post(`/buk-migration-trigger-log/execute-manual-licence-migration-buk-to-geo-victoria`, params);

            return {
                success: true,
                status: response.status,
                data: response.data.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    getBukMigrationTriggerLog: async (params: {page: number, pageSize: number, startDate: string, endDate: string}): Promise<ApiResponse<ServerSideDataSchema<GeoVictoriaMigrationLogRowSchema> | ApiErrorData>> => {
        try {
            const response = await MainBackendAxios.post(`/buk-migration-trigger-log/get-by-filters-and-paginate`, params);

            return {
                success: true,
                status: response.status,
                data: response.data.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
};
