import React from 'react';
import {DatePicker, Divider, Form, Input, Modal, Select} from "antd";

interface BukMigrationModalProps {
    isBukMigrationModalOpen: boolean,
    handleBukMigrationModalOk: (date: string, type: string) => void;
    handleBukMigrationModalCancel: () => void;
    loading: boolean;
}

function BukMigrationModal({ isBukMigrationModalOpen, handleBukMigrationModalOk, handleBukMigrationModalCancel, loading }: BukMigrationModalProps) {
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        handleBukMigrationModalOk(values.date.format('YYYY-MM-DD'), 'BUK_LICENCE_MIGRATION_TO_GEO_VICTORIA_PROCESS');
    };

    return (
        <Modal
            title="Generar migración manual"
            open={isBukMigrationModalOpen}
            onOk={() => { form.submit(); }}
            onCancel={handleBukMigrationModalCancel}
            okText="Generar migración"
            maskClosable={false}
            confirmLoading={loading}
            destroyOnClose
        >
            <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item
                    name="date"
                    label="Fecha"
                    rules={[
                        { required: true, message: 'El campo es requerido' },
                    ]}
                >
                    <DatePicker allowClear={false} style={{ width: '100%' }} format="DD/MM/YYYY"/>
                </Form.Item>
                <Form.Item
                    name="type"
                    label="Tipo de migración"
                >
                    <Input type="text" defaultValue="Licencias" style={{ width: '100%', fontWeight: 'bold' }} readOnly />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default BukMigrationModal;
